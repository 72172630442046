import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { Footer } from './Components/Footer';
import { HeaderNavbar } from './Components/Navbar';
import { EstimateCalculator } from './Components/EstimateCalculator';
import { Main } from './Components/StaticMain'

function App() {
  let startPath = "";
  return (
    <div className="App">
      <HeaderNavbar />

      <BrowserRouter>
        <Routes>
          <Route path={startPath + '/estimate'} element={<EstimateCalculator />}>
          </Route>
          <Route path={startPath + '/'} element={<Main />}>
          </Route>
          <Route path={startPath + '/#contact'}>
          </Route>
        </Routes>
      </BrowserRouter>

      <Footer />
    </div>
  );
}

export default App;
