import { FormControlLabel, FormGroup, InputAdornment, Switch, TextField } from "@mui/material"
import { useEffect, useState } from "react"
import '../Style/calculator.scss'

export const RestretchCalc = (props) => {
    const per_100_miles_cost = props.per_100_miles_cost
    const miles_before_charge = props.miles_before_charge
    const rooms_per_day = [6, 4, 2]
    const cost_per_room = 100

    const [inBaker, setInBaker] = useState(true)
    const [driveMiles, setDriveMiles] = useState(0)
    const [numberRooms, setNumberRooms] = useState(0)
    const [estimated, setEstimated] = useState(false)
    const [roomCost, setRoomCost] = useState(0)
    const [travelCost, setTravelCost] = useState([0, 0, 0])

    const [lowRange, setLowRange] = useState(0)
    const [midRange, setMidRange] = useState(0)
    const [hiRange, setHiRange] = useState(0)
    const [showBreakdown, setShowBreakdown] = useState(false)

    useEffect(() => {
        estimateRestretching()
    }, [driveMiles, inBaker, numberRooms])

    const calculateTravel = (number_days) => {
        let projectMileage = [0, 0, 0]
        let charge_mileage = driveMiles > miles_before_charge
        let mileageCost = driveMiles > 0 ? Math.max(Math.floor(driveMiles/100), 1) : 0
        mileageCost *= per_100_miles_cost
        if(charge_mileage) {
            for(let i = 0; i < 3; i++)
            projectMileage[i] = number_days[i] * mileageCost
        }
        return projectMileage
    }

    const estimateRestretching = () => {
        if(numberRooms === null || numberRooms === 0) {
            setEstimated(false)
            return;
        }

        let cost_for_rooms = numberRooms * cost_per_room;
        let number_days = [0, 0, 0]
        for(let i = 0; i < 3; i++) {
            number_days[i] = Math.max(Math.round(numberRooms/rooms_per_day[i]), 1)
        }
        let travel = calculateTravel(number_days)

        setHiRange(travel[2] + cost_for_rooms)
        setMidRange(travel[1] + cost_for_rooms)
        setLowRange(travel[0] + cost_for_rooms)
        setTravelCost(travel)
        setRoomCost(cost_for_rooms)
        setEstimated(true)
    }

    return (
        <div>
            <form>
                <FormGroup className="estimate-form">
                    <div className="estimate-subtitle">Carpet Restretching</div>
                    <TextField
                        id="numberrooms"
                        label="Number of Rooms"
                        variant='outlined'
                        value={numberRooms}
                        type="number"
                        onChange={e => {
                            e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,2)
                            setNumberRooms(e.target.value)
                        }}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">rooms</InputAdornment>,
                        }}
                        fullWidth={true}
                        required={true}
                    />
                    <FormControlLabel 
                        control={<Switch />} 
                        checked={inBaker}
                        onChange={e => {
                            setInBaker(!inBaker)
                            setDriveMiles(0)
                        }}
                        label="Located in Baker, MT"
                    />
                    {!inBaker &&
                            <div>
                                <span className="space-top"></span>
                                <TextField
                                id="query"
                                label="Miles From Baker"
                                variant='outlined'
                                value={driveMiles}
                                type="number"
                                onChange={e => {
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                                    setDriveMiles(e.target.value)
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment>miles</InputAdornment>,
                                }}
                                required={true}
                            />
                            </div>
                        }
                    {estimated ? 
                        <div>
                            <div className='success estimate-result'>
                                <div className="estimate-low estimate-value">Low: ${lowRange}</div> 
                                <div className="estimate-mid estimate-value">Mid: ${midRange}</div>
                                <div className="estimate-hi estimate-value">High: ${hiRange}</div>
                            </div> 
                            <FormControlLabel 
                                control={<Switch />} 
                                checked={showBreakdown}
                                onChange={e => {setShowBreakdown(!showBreakdown)}}
                                label="Show Breakdown"
                            />
                            {showBreakdown &&
                                <div className="breakdown">
                                    <div>
                                        Rooms: ${roomCost} (avg. $100/room)
                                    </div>
                                    {!inBaker && <div>
                                        Travel: {travelCost.map(value => {
                                            return <span className="space-left-right">${value}</span>
                                        })}
                                    </div>}
                                </div>
                            }
                        </div>
                        : 
                        <p></p>
                    }
                </FormGroup>
            </form>
        </div>
    );
}