import React from "react";
import '../Style/site.scss'
import '../Style/servicearea.scss'

export const ServiceAreas = () => {
    return (
        <div className="right-left-container">
            <div className="map">
                <iframe 
                    width="600" 
                    height="450" 
                    style={{border: 0}} 
                    loading="lazy" 
                    allowFullScreen
                    title="service-area" 
                    src="https://www.google.com/maps/embed/v1/view?zoom=8&center=46.3695%2C-104.2774&key=AIzaSyBA5pD931NzuRC-18fR-R8z_VtETJaKNAs">
                </iframe>
            </div>
            <div className="service-areas">
                <h1>Serving</h1>
                <ul>
                    <li><span>Baker, MT</span></li>
                    <li><span>Wibaux, MT</span></li>
                    <li><span>Ekalaka, MT</span></li>
                    <li><span>Glendive, MT</span></li>
                    <li><span>Beach, ND</span></li>
                    <li><span>Bowman, ND</span></li>
                    <li><span>+Surrounding Areas</span></li>
                </ul>
            </div>
        </div>
    );
}