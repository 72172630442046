import React, { useEffect, useState } from 'react'
import '../Style/contact.scss'
import '../Style/form.scss'
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { FormGroup } from '@mui/material';
import emailjs from '@emailjs/browser';
import { MuiTelInput } from 'mui-tel-input'

const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const Contact = () => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [query, setQuery] = useState("")
    const [errorText, setErrorText] = useState("")

    const [nameError, setNameError] = useState(false)
    const [emailError, setEmailError] = useState(false)
    const [phoneError, setPhoneError] = useState(false)
    const [queryError, setQueryError] = useState(false)
    const [submitError, setSubmitError] = useState(false)
    const [submitted, setSubmitted] = useState(false);

    const resetErrors = () => {
        setNameError(false);
        setEmailError(false);
        setPhoneError(false);
        setQueryError(false);
        setErrorText("")
    }

    const handlePhoneUpdate = (value) => {
        console.log(value)
        if(!value.startsWith("+1")) { value = "+1" + value; }
        setPhone(value);
    }

    useEffect(() => {
        emailjs.init("o7NzkEu3XxNqoHOsz");
        setSubmitError(nameError || emailError || phoneError || queryError);
    }, [nameError, phoneError, emailError, queryError]);

    const handleSubmit = async (e) => {
        e.preventDefault()
        resetErrors();

        let stop = false;
        if(name === "") {
            setNameError(true);
            stop = true;
        }
        if(email === "" || !re.test(email.toLowerCase())) {
            setErrorText("Invalid email address.")
            setEmailError(true)
            stop = true;
        }
        if(phone === "") {
            setPhoneError(true);
            stop = true;
        }
        if(query === "") {
            setQueryError(true);
            stop = true;
        }
        if(stop) {
            console.log("early exit")
            return;
        }

        try {
            const serviceId = "service_gct5rxa";
            const templateId = "template_b9aep4o";
            await emailjs.send(serviceId, templateId, {
                name: name,
                message: query,
                phone: phone,
                email: email
            });
            // console.log("email successfully sent check inbox");
            setSubmitted(true)
          } catch (error) {
            console.log(error);
            setSubmitError("Error sending email")
          }
  }

  return (
    <div className="form">
      <div className="title">Let's Talk</div>
      <div className="subtitle">Contact us for quotes or questions on your flooring project.</div>
      <form onSubmit={e => { handleSubmit(e); }}>
        <FormGroup className='form-field-wrapper'>       
                <TextField
                    error={nameError}
                    id="name"
                    label="Name"
                    variant='outlined'
                    value={name}
                    onChange={e => {setName(e.target.value)}}
                    fullWidth={true}
                    required={true}
                />
                <TextField
                    error={emailError}
                    id="email"
                    label="Email"
                    variant='outlined'
                    value={email}
                    fullWidth={true}
                    onChange={e => {setEmail(e.target.value)}}
                    required={true}
                />
                <MuiTelInput 
                    error={phoneError}
                    id="phone"
                    label="Phone"
                    variant='outlined'
                    value={phone}
                    onChange={e => {handlePhoneUpdate(e)}}
                    required={true}
                    defaultCountry='US'
                    onlyCountries={["US"]}
                    fullWidth={true}
                    inputProps={{ maxLength: 15 }}
                />
                <TextField
                    error={queryError}
                    id="query"
                    label="Message"
                    variant='outlined'
                    value={query}
                    multiline
                    rows={5}
                    onChange={e => {setQuery(e.target.value)}}
                    required={true}
                    fullWidth={true}
                />
                <Button
                    variant="outlined" 
                    type="submit" 
                    id="submit"
                > Submit</Button>
                {submitError ? <p className='error'>Error submitting form: {errorText}</p> : <p></p>}
                {submitted ? <p className='success'>Message sent successfully.</p> : <p></p>}
            </FormGroup>
        </form>
        <div className='alternate-contact'>
            <p>Prefer to call or email?</p>
            <ul>
                <li><span>Phone:</span>(406) 948 - 2223</li> 
                <li><span>Email:</span>morastaum@gmail.com</li> 
            </ul>
        </div>
    </div>
  );
};