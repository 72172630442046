import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import { InputLabel } from '@mui/material';

const material_map = {
    "carpet": "Carpet",
    "sheetvinyl": "Sheet Vinyl/Linoleum",
    "plankvinyl": "Vinyl Plank",
    "laminate": "Laminate",
    "carpetsqs": "Carpet Squares"
}
export const MaterialSelect = (props) => {
    return (
        <div>
            <FormControl>
            <InputLabel id="material-select-label">Material</InputLabel>
            <Select
                labelId="material-select-label"
                label="Material"
                variant='outlined'
                value={props.value}
                onChange={e => props.callback(e.target.value)}>
                {Object.keys(material_map).map(value => {
                        return <MenuItem key={value} value={value}>{material_map[value]}</MenuItem>
                    })
                }
            </Select>
            </FormControl>
        </div>
    )
}