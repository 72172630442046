import carpet1 from '../images/carpet.jpeg'
import carpet2 from '../images/carpet1.jpg'
import carpetsq1 from '../images/carpetsqs1.jpg'
import carpetsq2 from '../images/carpetsqs2.jpeg'
import laminate1 from '../images/laminate1.jpg'
import laminate2 from '../images/laminate2.jpg'
import tile1 from '../images/tile1.webp'
import tile2 from '../images/tile2.jpg'
import linoleum1 from '../images/linoleum1.jpg'
import linoleum2 from '../images/linoleum2.jpeg'


export const images = [
    {
        src: carpet1,
        width: 16,
        height: 12,
        caption: "carpet"
    },
    {
        src: carpet2,
        width: 12,
        height: 12,
        caption: "carpet"
    },
    {
        src: carpetsq1,
        width: 16,
        height: 12,
        caption: "carpet squares"
    },
    {
        src: carpetsq2,
        width: 12,
        height: 12,
        caption: "carpet squares"
    },
    {
        src: linoleum1,
        width: 20,
        height: 12,
        caption: "linoluem"
    },
    {
        src: linoleum2,
        width: 22,
        height: 12,
        caption: "linoluem"
    },
    {
        src: laminate1,
        width: 20,
        height: 12,
        caption: "laminate"
    },
    {
        src: laminate2,
        width: 25,
        height: 12,
        caption: "laminate"
    },
    {
        src: tile1,
        width: 20,
        height: 12,
        caption: "tile"
    },
    {
        src: tile2,
        width: 20,
        height: 12,
        caption: "tile"
    }
]