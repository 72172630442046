import React from "react";
import logo from '../images/logo/blue_logo.png';
import '../Style/banner.scss'

export const LogoBanner = () => {
    return (
        <div className="banner">
            <div className='halfmoon'>
                <div>
                    <img className="logo" src={logo} alt="small-logo"/> 
                </div>
            </div>
            {/* <img src={banner} style={{visibility: 'hidden'}}/> */}
        </div>
    );
}