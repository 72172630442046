import React, { useEffect, useState } from "react";
import '../Style/calculator.scss';
import { Button, FormControlLabel, FormGroup, InputAdornment, Switch, TextField } from "@mui/material";
import { MaterialSelect } from "./MaterialSelect";
import { RestretchCalc } from "./RestretchCalculator";
import _debounce from 'lodash.debounce'

const removal_cost_map = {
    "carpet": [1.50, 3.00, 6.00],
    "sheetvinyl": [1.50, 3.00, 5.50],
    "plankvinyl": [1.50, 3.50, 6.00],
    "laminate": [1.00, 2.25, 3.00],
    "carpetsqs": [2.00, 4.50, 6.00]
}

const install_cost_map = {
    "carpet": [2.00, 3.00, 5.50],
    "sheetvinyl": [2.00, 3.00, 5.00],
    "plankvinyl": [2.00, 3.50, 6.50],
    "laminate": [1.50, 2.50, 4.50],
    "carpetsqs": [2.00, 4.00, 6.00]
}

const finish_cost  = [1.50, 2.50, 4.50]
const per_100_miles_cost = 75
const miles_before_charge = 25  // i.e. within 25 miles, no charge
const ft_per_day = [500, 250, 150]

export const EstimateCalculator = () => {
    const [removal, setRemoval] = useState(true)
    const [install, setInstall] = useState(true)
    const [finish, setFinish] = useState(true)
    const [showBreakdown, setShowBreakdown] = useState(false)
    const [inBaker, setInBaker] = useState(true)

    const [removalType, setRemovalType] = useState("carpet")
    const [installType, setInstallType] = useState("carpet")

    const [totalLength, setTotalLength] = useState()
    const [totalWidth, setTotalWidth] = useState()
    const [driveMiles, setDriveMiles] = useState()

    const [errorText, setErrorText] = useState("")
    const [error, setError] = useState(false)
    const [estimated, setEstimated] = useState(false)

    const [lowRange, setLowRange] = useState(0)
    const [midRange, setMidRange] = useState(0)
    const [hiRange, setHiRange] = useState(0)
    const [removalBreakdown, setRemovalBreakdown] = useState([])
    const [installBreakdown, setInstallBreakdown] = useState([])
    const [finishBreakdown, setFinishBreakdown] = useState([])
    const [travelBreakdown, setTravelBreakdown] = useState([])


    const [width, setWidth] = useState(window.innerWidth)

    useEffect(() => {
        const handleResize = _debounce(() => setWidth(window.innerWidth), 100)

        window.addEventListener('resize', handleResize);

        performEstimate()
    }, [finish, install, removal, driveMiles, removalType, installType, totalLength, totalWidth, window.innerWidth, width])

    const calculateTravel = (number_days) => {
        let projectMileage = [0, 0, 0]
        let charge_mileage = driveMiles > miles_before_charge
        let mileageCost = driveMiles > 0 ? Math.max(Math.floor(driveMiles/100), 1) : 0
        mileageCost *= per_100_miles_cost
        if(charge_mileage) {
            for(let i = 0; i < 3; i++)
            projectMileage[i] = number_days[i] * mileageCost
        }
        return projectMileage
    }

    const performEstimate = () => {
        setError(false)
        setErrorText("")
        setEstimated(false)
        setTravelBreakdown([0,0,0])

        if(totalLength === 0 || totalWidth === 0 || totalLength == null || totalWidth == null) {
            setErrorText("Enter a room size to get started.")
            setError(true)
            setEstimated(false)
            return
        }

        let removalPrices = [0, 0, 0]
        let installPrices = [0, 0, 0]
        let finishPrices = [0, 0, 0]
        let i = 0;

        let sq_footage = totalLength * totalWidth
        let linear_footage = (2 * totalLength) + (2 * totalWidth)
        let total_feet = linear_footage + sq_footage
        let numberDays = [0, 0, 0]
        for(let i = 0; i < 3; i++) {
            numberDays[i] = Math.max(Math.round(total_feet/ft_per_day[i]), 1)
        }
        let projectMileage = calculateTravel(numberDays)

        console.log("Calculating estimates for ", sq_footage, " sq ft linear footage=", linear_footage, " removal=", removal, "-", removalType, 
                    " install=", install, "-", installType, " finish=", finish, " mileage=", driveMiles,
                    " total_feet=", total_feet, "mileagecost=", projectMileage)
                    
        setTravelBreakdown(projectMileage)
        if(removal) {
            let prices = removal_cost_map[removalType];
            i = 0;
            while(i < prices.length) {
                removalPrices[i] = Math.round(prices[i] * sq_footage)
                i++
            }
        }
        if(install) {
            let prices = install_cost_map[installType];
            i = 0;
            while(i < prices.length) {
                installPrices[i] = Math.round(prices[i] * sq_footage)
                i++
            }
        }
        if(finish) {
            i = 0;
            while(i < finish_cost.length) {
                finishPrices[i] = Math.round(finish_cost[i] * linear_footage)
                i++
            }
        }

        console.log(projectMileage)
        let low = Math.round(removalPrices[0] + finishPrices[0] + installPrices[0] + projectMileage[0]);
        let mid = Math.round(removalPrices[1] + finishPrices[1] + installPrices[1] + projectMileage[1]);
        let hi = Math.round(removalPrices[2] + finishPrices[2] + installPrices[2] + projectMileage[2]);

        setRemovalBreakdown(removalPrices)
        setInstallBreakdown(installPrices)
        setFinishBreakdown(finishPrices)

        setLowRange(low)
        setMidRange(mid)
        setHiRange(hi)
        setEstimated(true)
    }

    return (
        <div className="calculator-large-wrapper ">
            <div>
                <div className="estimate-title">Installation Cost Calculator</div>
                <form onSubmit={e => { 
                            e.preventDefault();
                            performEstimate(); 
                        }} className="">
                    <FormGroup className="estimate-form">
                        <FormGroup row={true} className="room-size">
                            <TextField
                                id="query"
                                label="Total Room(s) Length"
                                variant='outlined'
                                value={totalLength}
                                type="number"
                                onChange={e => {
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                                    setTotalLength(e.target.value)
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">ft</InputAdornment>,
                                }}
                                required={true}
                            />
                            {window.innerWidth > 1780 && <span className="space-right"></span>}
                            <TextField
                                id="query"
                                label="Total Room(s) Width"
                                variant='outlined'
                                value={totalWidth}
                                type="number"
                                onChange={e => {
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                                    setTotalWidth(e.target.value)
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment>ft</InputAdornment>,
                                }}
                                required={true}
                            />
                        </FormGroup>
                        <FormControlLabel 
                            control={<Switch />} 
                            checked={removal}
                            onChange={e => {setRemoval(!removal)}}
                            label="Removal"
                        />
                        {removal &&
                            <div>
                                <span className="space-top"></span>
                                <MaterialSelect 
                                    value={removalType}
                                    callback={setRemovalType}
                                />
                            </div>
                        }
                        <FormControlLabel 
                            control={<Switch />} 
                            checked={install}
                            onChange={e => {setInstall(!install)}}
                            label="Installation"
                        />
                        {install &&
                            <div>
                                <span className="space-top"></span>
                                <MaterialSelect 
                                    value={installType}
                                    callback={setInstallType}
                                />
                            </div>
                        }
                        <FormControlLabel 
                            control={<Switch />} 
                            checked={finish}
                            label="Finishing (Trimming)"
                            onChange={e => {setFinish(!finish)}}
                        />
                        <FormControlLabel 
                            control={<Switch />} 
                            checked={inBaker}
                            onChange={e => {
                                setInBaker(!inBaker)
                                setDriveMiles(0)
                            }}
                            label="Located in Baker, MT"
                        />
                        {!inBaker &&
                            <div>
                                <span className="space-top"></span>
                                <TextField
                                id="query"
                                label="Miles From Baker"
                                variant='outlined'
                                value={driveMiles}
                                type="number"
                                onChange={e => {
                                    e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,4)
                                    setDriveMiles(e.target.value)
                                }}
                                InputProps={{
                                    endAdornment: <InputAdornment>miles</InputAdornment>,
                                }}
                                required={true}
                            />
                            </div>
                        }
                        {/* <span className="space-bottom"></span>
                        <Button
                            variant="outlined" 
                            type="submit" 
                            id="submit"
                        >Estimate</Button> */}

                        {error ? <p className='success'>{errorText}</p> : <p></p>}
                        {estimated ? 
                            <div>
                                <div className='success estimate-result'>
                                    <div className="estimate-low estimate-value">Low: ${lowRange}</div> 
                                    <div className="estimate-mid estimate-value">Mid: ${midRange}</div>
                                    <div className="estimate-hi estimate-value">High: ${hiRange}</div>
                                </div> 
                                <FormControlLabel 
                                    control={<Switch />} 
                                    checked={showBreakdown}
                                    onChange={e => {setShowBreakdown(!showBreakdown)}}
                                    label="Show Breakdown"
                                />
                                {showBreakdown &&
                                    <div className="breakdown">
                                        {removal && <div>
                                            Removal: {removalBreakdown.map(value => {
                                                return <span className="space-right space-left">${value}</span>
                                            })}
                                        </div>}
                                        {install && <div>
                                            Installation: {installBreakdown.map(value => {
                                                return <span className="space-right space-left">${value}</span>
                                            })}
                                        </div>}
                                        {finish && <div>
                                            Finishing: {finishBreakdown.map(value => {
                                                return <span className="space-right space-left">${value}</span>
                                            })}
                                        </div>}
                                        {!inBaker && <div>
                                            Travel: {travelBreakdown.map(value => {
                                                return <span className="space-right space-left">${value}</span>
                                            })}
                                        </div>}
                                    </div>
                                }
                            </div>
                            : 
                            <p></p>}
                    </FormGroup>
                </form>
            </div>
            <div className="other-services-wrapper">
                <div className="estimate-title">Other Services Calculator</div>
                <RestretchCalc
                    per_100_miles_cost={per_100_miles_cost}
                    miles_before_charge={miles_before_charge}
                />
            </div>
            <div className="estimate-subtitle disclaimer">
                <b>Disclaimer:</b> This estimate is not guaranteed. The price range in the estimate 
                    is an approximation of the project requirements as described by the form. The actual cost may 
                    change after all elements of the project have been described, negotiated, and finalized. The client will be informed 
                    of any changes in cost in a more formal estimate.
            </div>
        </div>
    );
}