import React from 'react';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../images/logo/white_logo.png';
import '../Style/navbar.scss';

export const HeaderNavbar = () => {
  return (
    <div className='navbar-wrapper'>
      {/* <Navbar collapseOnSelect className="navbar sticky" fixed="top"> */}
      <Navbar collapseOnSelect expand="lg" className="navbar sticky" fixed="top" variant="dark">
        <Navbar.Brand href="/"><img src={logo} className="navbar-logo" alt="small-logo"/></Navbar.Brand>
        <Navbar.Toggle />
        <Navbar.Collapse>
          <Nav className='ms-auto navitems'>
            <Nav.Item>
              <Nav.Link href="/#services">Services</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/#service-areas">Service Areas</Nav.Link>
            </Nav.Item>
            {/* <Nav.Item>
              <Nav.Link href="/#portfolio">Portfolio</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="/#testimonials">Testimonials</Nav.Link>
            </Nav.Item> */}
            <Nav.Item>
              <Nav.Link href="/#contact">Contact</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="1" href="/estimate">Estimate Calculator</Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
}