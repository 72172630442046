import React from "react";
import { images } from "./Images.js";
import '../Style/site.scss'
import '../Style/services.scss'
import { Gallery } from "react-grid-gallery";

export const Services = () => {
    return (
        <div className="right-left-container">
            <div className="information">
                <h1>Services</h1>
                <p>Morast Floors is your one-stop-shop for flooring removal, installation, and finishing.</p>
                <p>We offer installation of the following flooring materials</p>
                <ul style={{ columns: 2 }}>
                    <li>Carpet</li>
                    <li>Tile</li>
                    <li>Carpet Squares</li>
                    <li>Linoluem</li>
                    <li>Laminate</li>
                    <li>Vinyl Flooring</li>
                </ul>
                <p>Don't see your material listed? Just ask!</p>
                <p>Additional flooring services</p>
                <ul style={{columns: 2}}>
                    <li>Carpet Restretching</li>
                    <li>Carpet Repair</li>
                    <li>Laminate Repair</li>
                    <li>Tile Repair</li>
                    <li><a href="#contact">Inquire About Other Services</a></li>
                </ul>
            </div>
            <div className="image-grid">
                <div className="gallery">
                    <Gallery
                        images={ images }
                        enableImageSelection={false}
                        maxRows={3}
                        rowHeight={120}
                        margin={4}
                    />
                </div>
            </div>
        </div>
    );
}