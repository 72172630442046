import React from 'react';
import logo from '../images/logo/blue_logo.png';
import '../Style/footer.scss'

export const Footer = () => (
  <div className="footer">
    <p>
      &copy; {new Date().getFullYear()} Copyright: <a href="https://www.morastfloors.com">morastfloors.com </a>
    </p>
    <img src={logo} className="footer-logo" alt="small-logo"/>
  </div>
);