import React from "react";
import { Contact } from "./Contact";
import { Services } from "./Services";
import { ServiceAreas } from "./ServiceArea";
import { LogoBanner } from "./LogoBanner";

import '../Style/main.scss'
// import { Portfolio } from "./Portfolio";
// import { Testimonial } from "./Testimonial";

export const Main = () => {
    return (
        <div>
            <LogoBanner/>
            <div id="main">
                <div className="main-item">
                    <div className="small-wrapper" id="services">
                        <Services/>
                    </div>
                </div>
                <div className="main-item">
                    <div className="small-wrapper" id="service-areas">
                        <ServiceAreas/>
                    </div>
                </div>
                {/* <div className="main-item">
                    <div className="small-wrapper" id="testimonials">
                        <Testimonial/>
                    </div>
                </div>
                <div className="main-item">
                    <div className="small-wrapper" id="portfolio">
                        <Portfolio/>
                    </div>
                </div> */}
                <div className="main-item">
                    <div className="small-wrapper" id="contact">
                        <Contact/>
                    </div>
                </div>
            </div>
        </div>
    );
}